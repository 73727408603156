import { all, call, fork, put, takeEvery, takeLeading, delay } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD,
  CHECK_USER,
  CLEAR_USER,
  FORGET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD, SETUP_ACCOUNT,
  REGISTER_SUBMIT_FORM,
  SEND_VERIFICATION_SMS,
  VERIFY_MOBILE_CODE,
  CHECK_POINTS_REQUEST,
} from 'state/auth/authConstants';
import {
  apiChangePassword,
  apiCheckUser,
  apiForgetPassword,
  apiResetPassword,
  apiSignInUser,
} from "api/auth/authApi";

import { apiSendVerificationSMS, apiVerifyMobileCode } from 'api/patient/PatientApi';

import {
  apiGeneratePatientAndConsultation,
  apiVIPRegister,
  apiCheckPoints,
} from "api/patient/PatientApi"
import {
  changePasswordFailed,
  changePasswordSuccess,
  clearUser,
  forgetPasswordFailed,
  forgetPasswordSuccess,
  loginUserFailed,
  loginUserSuccess,
  logoutUser,
  logoutUserSuccess,
  refreshUser, resetPasswordFailed, resetPasswordSuccess, setupAccountFailed, setupAccountSuccess,
  formSubmitted,
  formUnsubmitted,
  vipformSubmitted,
  sendVerificationSMSSuccess,
  sendVerificationSMSFailed,
  // verifyMobileCodeSuccess,
  verifyMobileCodeFailed,
  checkPointsSuccess,
  checkPointsFailure,
} from 'state/auth/authActions';
import { clearLocalStorageToken, logoutLocally, redirectToLogin, setLocalToken, } from "helpers/authUtils";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { LOGOUT_PATH } from "routes/auth/constants";
import history from 'routes/history';
import { toastCypress, toastDefault } from "state/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";


function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchClearUser),
    fork(watchCheckUser),
    fork(watchForgetPassword),
    fork(watchResetPassword),
    fork(watchChangePassword),
    fork(watchSetupAccount),
    fork(watchRegisterUser),
    fork(watchSendVerificationSMS),
    fork(watchVerifyMobileCode),
    fork(watchCheckPoints),
  ]);
}

// Watcher Saga: Watch for CHECK_POINTS_REQUEST
export function* watchCheckPoints() {
  yield takeLeading(CHECK_POINTS_REQUEST, checkPointsSaga);
}

// Worker Saga: Check Points
export function* checkPointsSaga(action) {
  try {
    const { mobile } = action.payload;
    const response = yield call(apiCheckPoints, { mobile });

    if (response.status === 200) {
      const { points } = response.data;
      yield put(checkPointsSuccess(points));
    } else {
      const error = response.data.error || 'Failed to retrieve points.';
      yield put(checkPointsFailure(error));
    }
  } catch (error) {
    const errorMessage = generateErrorMessage(error);
    yield put(checkPointsFailure(errorMessage));
  }
}

export function* watchSendVerificationSMS() {
  yield takeLeading(SEND_VERIFICATION_SMS, sagaSendVerificationSMS);
}

export function* sagaSendVerificationSMS({ payload }) {
  try {
    yield call(apiSendVerificationSMS, payload);
    yield put(sendVerificationSMSSuccess());
  } catch (error) {
    const errorMessage = generateErrorMessage(error);
    yield put(sendVerificationSMSFailed({ error: errorMessage }));
    // Optionally log the error
    console.error('sagaSendVerificationSMS error:', errorMessage);
    // Remove throwFrontError(error); if you handle the error here
  }
}

export function* watchVerifyMobileCode() {
  yield takeLeading(VERIFY_MOBILE_CODE, sagaVerifyMobileCode);
}

export function* sagaVerifyMobileCode({ payload }) {
  try {
    yield call(apiVerifyMobileCode, payload);
    // 验证成功后，继续注册用户
    const { mobile, patientData } = payload;
    console.log('sagaVerifyMobileCode mobile', mobile);
    console.log('sagaVerifyMobileCode patientData', patientData);

    // 准备患者数据
    const patient = {
      ...patientData,
      mobile,
    };

    // 调用 VIP 注册 API
    const response = yield call(apiVIPRegister, { patient });
    const registeredPatient = response.data.patient;

    console.log('sagaVerifyMobileCode registeredPatient', registeredPatient);

    // 派发 vipformSubmitted action
    yield put(vipformSubmitted({ patient: registeredPatient }));
  } catch (error) {
    console.log('sagaVerifyMobileCode error');
    console.log(error);
    console.log(generateErrorMessage(error));
    yield put(verifyMobileCodeFailed({ error: generateErrorMessage(error) }));
    throwFrontError(error);
  }
}

export function* watchRegisterUser() {
  yield takeLeading(REGISTER_SUBMIT_FORM, submitFormAsync);
}

export function* submitFormAsync({payload}) {

  try {
    const response = yield call(apiGeneratePatientAndConsultation, payload);
    const patient = response.data;
    yield put(formSubmitted({patient}));
  } catch (error) {
    // 这里需要判断一下 error 是什么类型的。特别是，patient exist的情况下，需要再页面显示phone already exist.
    yield put(formUnsubmitted(generateErrorMessage(error)));
  }
}

// Login User
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, sagaLogin);
}

export function* sagaLogin({ payload }) {
  try {
    const response = yield call(apiSignInUser, payload);
    setLocalToken(response.data.token);
    yield put(loginUserSuccess(response.data));

  } catch (e) {
    yield put(loginUserFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Logout User
export function* watchLogoutUser() {
  yield takeLeading(LOGOUT_USER, sagaLogout);
}

export function* sagaLogout() {
  try {
    // const response = yield call(apiSignOutUser);
    yield call(() => {
      logoutLocally();
    });
    yield put(logoutUserSuccess());

  } catch (e) {
    yield call(() => {
      logoutLocally();
    });
    throwFrontError(e);
  }
}

// Clear User
export function* watchClearUser() {
  yield takeEvery(CLEAR_USER, sagaClearUser);
}

export function* sagaClearUser() {
  try {
    yield call(() => {
      clearLocalStorageToken();
    });

  } catch (e) {
    yield call(() => {
      clearLocalStorageToken();
    });
    throwFrontError(e);
  }
}

// Check User
export function* watchCheckUser() {
  yield takeEvery(CHECK_USER, sagaCheckUser);
}

export function* sagaCheckUser() {
  try {
    const response = yield call(apiCheckUser);

    if (!response.data.currentAuth) {
      history.push(LOGOUT_PATH);
    } else {
      yield put(refreshUser({ current: response.data }));
    }


  } catch (e) {
    yield put(logoutUser());
    // yield put(initMenu());
    throwFrontError(e);
  }
}

// Watch Forget Password
export function* watchForgetPassword() {
  yield takeLeading(FORGET_PASSWORD, sagaForgetPassword);
}

export function* sagaForgetPassword({ payload }) {
  try {
    const response = yield call(apiForgetPassword, payload);
    yield put(forgetPasswordSuccess({ passwordResetStatus: response.data.status }));

    if (response.data.cypress) {
      yield put(toastCypress({ message: response.data.cypress }));
    }

  } catch (e) {
    yield put(forgetPasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Reset Password
export function* watchResetPassword() {
  yield takeLeading(RESET_PASSWORD, sagaResetPassword);
}

export function* sagaResetPassword({ payload }) {
  try {
    // const response = yield call(apiResetPassword, payload);

    yield put(resetPasswordSuccess());
    yield put(clearUser());
    yield put(toastDefault({ message: 'Password has been reset. Please login to proceed.' }));
    yield call(() => {
      redirectToLogin();
    });

  } catch (e) {
    yield put(resetPasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Change Password
export function* watchChangePassword() {
  yield takeLeading(CHANGE_PASSWORD, sagaChangePassword);
}

export function* sagaChangePassword({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiChangePassword, payload);
    console.log(response);
    yield put(changePasswordSuccess());
    yield put(logoutUser());
    yield put(toastDefault({ message: 'Password has been updated. Please login to proceed.' }));

  } catch (e) {
    yield put(changePasswordFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Watch Setup Account
export function* watchSetupAccount() {
  yield takeLeading(SETUP_ACCOUNT, sagaSetupAccount);
}

export function* sagaSetupAccount({ payload }) {
  try {
    const response = yield call(apiResetPassword, payload);

    yield call(() => {
      setLocalToken(response.data.token);
    })
    history.push(OVERVIEW_DASHBOARD_PATH);

    yield put(setupAccountSuccess());
    yield put(loginUserSuccess(response.data));
    yield put(toastDefault({ message: 'Welcome.' }));

  } catch (e) {
    yield put(setupAccountFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// // Watch Fetch Current Staff
// export function* watchFetchCurrentStaff() {
//   yield takeEvery(FETCH_CURRENT_STAFF, sagaFetchCurrentStaff);
// }
//
// export function* sagaFetchCurrentStaff({ payload }) {
//   try {
//     const response = yield call(apiFetchCurrentStaff);
//
//     yield put(fetchCurrentStaffSuccess({ current: response.data }));
//
//   } catch (e) {
//     yield put(fetchCurrentStaffFailed(generateErrorMessage(e)));
//     throwFrontError(e);
//   }
// }

export default authSaga;
