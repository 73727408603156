import React from "react";

import {
  ACCOUNT_PATH,
  FORGET_PASSWORD_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  RESET_PASSWORD_PATH, SETUP_ACCOUNT_PATH,
  REGISTER_PATH,
  VIP_REGISTER_PATH,
  CHECK_POINTS_PATH,
} from "routes/auth/constants";
import { Route } from "react-router-dom";

const Login = React.lazy(() => import('pages/auth/Login'));
const Logout = React.lazy(() => import('pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/ResetPassword'));
const SetupAccount = React.lazy(() => import('pages/auth/SetupAccount'));
const Register = React.lazy(() => import('pages/auth/Register'));
const VIPRegister = React.lazy(() => import('pages/auth/VIPRegister'));
const CheckPoints = React.lazy(() => import('pages/auth/CheckPoints'));

export const authRoutes = {
  path: ACCOUNT_PATH,
  name: 'Auth',
  children: [
    {
      path: LOGIN_PATH,
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: REGISTER_PATH,
      name: 'Register',
      component: Register,
      route: Route,
    },
    {
      path: LOGOUT_PATH,
      name: 'Logout',
      component: Logout,
      route: Route,
    },
    {
      path: FORGET_PASSWORD_PATH,
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
    {
      path: RESET_PASSWORD_PATH,
      name: 'Reset Password',
      component: ResetPassword,
      route: Route,
    },
    {
      path: SETUP_ACCOUNT_PATH,
      name: 'Setup Account',
      component: SetupAccount,
      route: Route,
    },
    {
      path: VIP_REGISTER_PATH,
      name: 'VIP Register',
      component: VIPRegister,
      route: Route,
    },
    {
      path: CHECK_POINTS_PATH,
      name: 'Check Points',
      component: CheckPoints,
      route: Route, 
    },
  ],
};
