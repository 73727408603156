/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';
export const LOG_PATH = 'LOG_PATH';
export const CHECK_USER = 'CHECK_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const CHANGE_THEME = 'CHANGE_THEME';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const SETUP_ACCOUNT = 'SETUP_ACCOUNT';
export const SETUP_ACCOUNT_SUCCESS = 'SETUP_ACCOUNT_SUCCESS';
export const SETUP_ACCOUNT_FAILED = 'SETUP_ACCOUNT_FAILED';

export const FETCH_CURRENT_STAFF = 'FETCH_CURRENT_STAFF';
export const FETCH_CURRENT_STAFF_SUCCESS = 'FETCH_CURRENT_STAFF_SUCCESS';
export const FETCH_CURRENT_STAFF_FAILED = 'FETCH_CURRENT_STAFF_FAILED';

export const REGISTER_SETUP_STEP  = "REGISTER_SETUP_STEP";
export const REGISTER_SET_USER_DATA = "REGISTER_SET_USER_DATA";
export const REGISTER_SUBMIT_FORM = "REGISTER_SUBMIT_FORM";

export const VIP_REGISTER_SUBMIT_FORM = "VIP_REGISTER_SUBMIT_FORM";
export const VIP_REGISTER_SUBMIT_FORM_SUCCESS = "VIP_REGISTER_SUBMIT_FORM_SUCCESS";
export const VIP_REGISTER_SUBMIT_FORM_FAILED = "VIP_REGISTER_SUBMIT_FORM_FAILED";

export const REGISTER_FORM_SUBMITED = "REGISTER_FORM_SUBMITED";
export const REGISTER_FORM_UNSUBMITED = "REGISTER_FORM_UNSUBMITED";
export const REGISTER_SETUP_CONSULTATION = "REGISTER_SETUP_CONSULTATION";

export const REGISTER_SETUP_ALLOW_TO_NEXT = "REGISTER_SETUP_ALLOW_TO_NEXT";

export const SEND_VERIFICATION_SMS = 'SEND_VERIFICATION_SMS';
export const SEND_VERIFICATION_SMS_SUCCESS = 'SEND_VERIFICATION_SMS_SUCCESS';
export const SEND_VERIFICATION_SMS_FAILED = 'SEND_VERIFICATION_SMS_FAILED';

export const VERIFY_MOBILE_CODE = 'VERIFY_MOBILE_CODE';
export const VERIFY_MOBILE_CODE_SUCCESS = 'VERIFY_MOBILE_CODE_SUCCESS';
export const VERIFY_MOBILE_CODE_FAILED = 'VERIFY_MOBILE_CODE_FAILED';

// 添加新的动作类型
export const CHECK_POINTS_REQUEST = 'CHECK_POINTS_REQUEST';
export const CHECK_POINTS_SUCCESS = 'CHECK_POINTS_SUCCESS';
export const CHECK_POINTS_FAILED = 'CHECK_POINTS_FAILED';

export const MUSCULOSKELETAL = [
    { label: 'Arthritis', value: 'Arthritis', id: 'Arthritis' },
    { label: 'Joint stiffness / Swelling', value: 'Joint stiffness / Swelling', id: 'Joint stiffness / Swelling' },
    { label: 'Muscle strain / Sprain', value: 'Muscle strain / Sprain', id: 'Muscle strain / Sprain' },
    { label: 'Spasms / Cramps', value: 'Spasms / Cramps', id: 'Spasms / Cramps' },
    { label: 'Osteoporosis', value: 'Osteoporosis', id: 'Osteoporosis' },
    { label: 'Broken / Fractured bones', value: 'Broken / Fractured bones', id: 'Broken / Fractured bones' },
    { label: 'Back problems', value: 'Back problems', id: 'Back problems' },
];
  
export const NERVOUS = [
    { label: 'Headaches', value: 'Headaches', id: 'Headaches' },
    { label: 'Sleep disorders', value: 'Sleep disorders', id: 'Sleep disorders' },
    {
      label: 'Numbness / Tingling / Weakness',
      value: 'Numbness / Tingling / Weakness',
      id: 'Numbness / Tingling / Weakness'
    },
];
  
export const CARDIOVASCULAR = [
    { label: 'Heart conditions', value: 'Heart conditions', id: 'Heart conditions' },
    { label: 'Varicose veins', value: 'Varicose veins', id: 'Varicose veins' },
    { label: 'Blood clots', value: 'Blood clots', id: 'Blood clots' },
    { label: 'High / Low blood pressure', value: 'High / Low blood pressure', id: 'High / Low blood pressure' },
];
  
export const RESPIRATORY = [
    { label: 'Breathing difficulties', value: 'Breathing difficulties', id: 'Breathing difficulties' },
    { label: 'Sinus problems', value: 'Sinus problems', id: 'Sinus problems' },
    { label: 'Hay fever', value: 'Hay fever', id: 'Hay fever' },
    { label: 'Asthma', value: 'Asthma', id: 'Asthma' },
];
  
export const REACTIONS = [
    { label: 'Allergies', value: 'Allergies', id: 'Allergies' },
    { label: 'Skin disorders', value: 'Skin disorders', id: 'Skin disorders' },
];
  
export const OTHER_CONDITIONS = [
    { label: 'Diabetes', value: 'Diabetes', id: 'Diabetes' },
    { label: 'Pregnancy', value: 'Pregnancy', id: 'Pregnancy' },
    { label: 'Epilepsy', value: 'Epilepsy', id: 'Epilepsy' },
    { label: 'Chronic pain', value: 'Chronic pain', id: 'Chronic pain' },
    { label: 'Cancer', value: 'Cancer', id: 'Cancer' },
    { label: 'Lymphatic system ', value: 'Lymphatic system ', id: 'Lymphatic system ' },
];
  
  const TREATMENT_REFERENCES = {
    head: [
      {
        conditions: 'Headache 2° up body stress',
        treatment: ['MET', 'STM'],
        afterTreatment: ['Muscle relaxed', 'headache improved'],
      },
    ],
    neck: [
      {
        conditions: 'Tension in neck 2° desk sitting',
        treatment: ['Neck stretches'],
        afterTreatment: ['Muscle relaxed'],
      },
      {
        conditions: 'Neck stiffness',
        treatment: ['DTM'],
        afterTreatment: ['Stiffness improved'],
      },
    ],
    shoulders: [
      {
        conditions: 'Neck & shoulders stiffness',
        treatment: ['DTM'],
        afterTreatment: ['Stiffness improved'],
      }, {
        conditions: 'R lev scap TrP found ',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP lev scap'],
      }, {
        conditions: 'Tightness across sh',
        treatment: ['DTM'],
        afterTreatment: ['Muscle relaxed'],
      }, {
        conditions: 'L up trap TrP found ',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
    ],
    arms: [
      {
        conditions: 'A ROM↓ R ARM P-6 (这个疼痛指数可以改)',
        treatment: ['MET', 'STM'],
        afterTreatment: ['P ↓', 'ROM↑'],
      }, {
        conditions: 'R ROM↓ L ARM',
        treatment: ['MET'],
        afterTreatment: ['ROM↑'],
      },
    ],
    back: [
      {
        conditions: 'Soft tissue adhesion',
        treatment: ['STM'],
        afterTreatment: ['Muscle relaxed '],
      },
      {
        conditions: 'Erector spinae tightness + TrP found',
        treatment: ['TPR', 'DTM'],
        afterTreatment: ['↓ TrP', 'Muscle relaxed'],
      },
      {
        conditions: 'Lower trap TrP',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
      {
        conditions: 'Back muscle strain',
        treatment: ['DTM'],
        afterTreatment: ['Muscle relaxed', 'P ↓'],
      },
    ],
    upBack: [
      {
        conditions: 'Rhomb TrP found ',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
      {
        conditions: 'Illo thor TrP found ',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
    ],
    lowerBack: [
      {
        conditions: 'AMC to LES & gluts',
        treatment: ['XFF over the region'],
        afterTreatment: ['AMC ↓', 'Muscle relaxed'],
      },
      {
        conditions: 'C/O loin P P-7 (这个疼痛指数可以改)',
        treatment: ['DTM'],
        afterTreatment: ['P ↓'],
      },
      {
        conditions: 'HT on QL from work (lifting & bending)',
        treatment: ['DTM'],
        afterTreatment: ['Muscle relaxed ', '↑ Lx ROM', 'P ↓'],
      },
      {
        conditions: 'LB muscle cramp ',
        treatment: ['STM'],
        afterTreatment: ['Muscle relaxed ', '↑ Lx ROM', 'P ↓'],
      },
    ],
    hip: [
      {
        conditions: 'Gluts TrPs R＞L（或者L＞R',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
      {
        conditions: 'Sciatica P-8 （这个疼痛指数可以改）',
        treatment: ['STM'],
        afterTreatment: ['P ↓'],
      },
      {
        conditions: 'Tension on glut from work out',
        treatment: ['PNF'],
        afterTreatment: ['Muscle relaxed', '↑ ROM'],
      },
    ],
    legs: [
      {
        conditions: 'Tightness across legs',
        treatment: ['PNF'],
        afterTreatment: ['Muscle relaxed'],
      },
    ],
    thigh: [
      {
        conditions: 'Tension on hamstrings after running',
        treatment: ['PNF'],
        afterTreatment: ['Muscle relaxed'],
      },
    ],
    calf: [
      {
        conditions: 'L calf muscle cramp this morning',
        treatment: ['DTM'],
        afterTreatment: ['Muscle relaxed', '↑ ROM'],
      },
      {
        conditions: 'R gastroc TrP',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
      {
        conditions: 'L sol TrP',
        treatment: ['TPR'],
        afterTreatment: ['↓ TrP'],
      },
      {
        conditions: 'oedema on ankles',
        treatment: ['STM', 'MLD'],
        afterTreatment: ['oedema ↓'],
      },
    ],
    FB: [
      {
        conditions: 'Chronic Pain',
        treatment: ['DTM'],
        afterTreatment: ['Muscle relaxed', 'P ↓'],
      },
      {
        conditions: 'fibromyalgia',
        treatment: ['DTM', 'Shiatsu'],
        afterTreatment: ['Muscle relaxed', 'P ↓'],
      },
    ],
  }
  
export const MASSAGE_REFERENCE = {
    head: TREATMENT_REFERENCES.head,
    Cx: TREATMENT_REFERENCES.neck,
    shoulders: TREATMENT_REFERENCES.shoulders,
    UT: TREATMENT_REFERENCES.upBack,
    LT: TREATMENT_REFERENCES.back,
    Rhom: TREATMENT_REFERENCES.back,
    Lx: TREATMENT_REFERENCES.lowerBack,
    QL: TREATMENT_REFERENCES.lowerBack,
    UL: TREATMENT_REFERENCES.arms,
    LL: TREATMENT_REFERENCES.legs,
    glut: TREATMENT_REFERENCES.hip,
    QH: TREATMENT_REFERENCES.thigh,
    calf: TREATMENT_REFERENCES.calf,
    FB: TREATMENT_REFERENCES.FB,
  }
  
export const FURTHER_SUGGESTIONS_REFERRAL = [
    'Recommended to stand and strech every 2 hours sitting',
    'Reduce lifting and bending',
    'Corrected lifting posture',
    'Educated on stretching skills',
    'Recommended stretching after exercise',
    'Recommended Physio Ax',
    'Recommended r/v in 1/52',
    'Recommended r/v in 2/52',
    'Recommended r/v in 1/12',
];
  
  /*
  * Complaint 症状表现
  */
  
  // Area of body 身体部位
export const AREA_OF_BODY = [
    "Head",
    "Arms",
    "Neck",
    "Shoulders",
    "Back",
    "Hips",
    "Legs",
    "Calf",
];
  
  // Type of Pain 疼痛种类 (自选1到2个)
export const TYPE_OF_PAIN = [
    "Aches",
    "Burning",
    "Dull",
    "Gripping",
    "Numbness",
    "sharp",
    "Shooting",
    "Sore",
    "Stabbing",
    "Throbbing",
    "Tingling",
    "Weak",
    "Other",
];
  
  // Pain Level (有多疼: 一般选1或者2)
export const PAIN_LEVEL = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
];
  
  // Frequency of Pain (疼痛频率, 自选1个)
export const FREQUENCY_OF_PAIN = [
    "Constant",
    "Daily",
    "Weekly",
    "Monthly",
    "Acute",
    "Chronic",
    "Interferes with Sleep",
];
  
  // Pain/Discomfort is made worse by (什么情况下疼痛加剧, 任选其一)
export const DISCOMFORT_IS_MADE_WORSE_BY = [
    "Sitting",
    "Lifting",
    "Walking",
    "Running",
    "Standing",
    "Bending",
    "Squating",
    "Exerise",
    "General Movement",
    "tress",
    "Other",
];
  
  // Feels better with (什么情况下疼痛缓解, 任选其一)
export const FEELS_BETTER_WITH = [
    "Rest",
    "Ice",
    "Heat",
    "Anti-inflamatory mediation",
    "Stretching",
    "movment",
    "other",
];
  
  
  /*
  * Assessment 评估病人
  */
  
  // 1.	Pain area Palpation 触诊 (一般都选最左边第一个最轻的症状)
export const PAIN_ARE_PALPATION_TENSION = [
    "Mild",
    "Moderate",
    "Severe",
];
  
export const PAIN_ARE_PALPATION_TEXTURE = [
    "Pliable",
    "Adhesive",
    "Fibrotic",
];
  
export const PAIN_ARE_PALPATION_TENDERNESS = [
    "Mild",
    "Modrate",
    "Severe",
];
  
export const PAIN_ARE_PALPATION_TEMPERATURE = [
    "Norma",
    "Increased",
    "Decreased",
];
  
  // 2.	Pain area RoM (疼痛区RMO测试)  都选最左面的
  
export const PAIN_AREA_ROM_ACTIVE = [
    "Full Range",
    "Slightly Restricted",
    "Moderatedly Restricted",
];
  
export const PAIN_AREA_ROM_PASSIVE = [
    "Full Range",
    "Slightly Restricted",
    "Moderatedly Restricted",
];
  
  // 3.	Posture (体位,都选NORMAL)
export const POSTURE_SHOULDER = [
    "Normal",
    "Tilt",
    "Twist",
    "Protract",
];
  
export const POSTURE_SPINE = [
    "Normal",
    "Lordosis",
    "Kyphosis",
    "Scoliosis",
];
  
export const POSTURE_PELVIS = [
    "Normal",
    "Tilt",
    "Twist",
    "Protract",
    "Retract",
];
  
  /*
  ** Treatment  治疗按摩手法
  ** (请根据病人按摩的部位和给客人做的手法,在下面手法或方框格子,任选其一或二)
  */
  
  // Head AND Neck (头和颈)
export const HEAD_AND_NECK_SUPERFICIAL_MUSCLES = [
    "Temporalis",
    "Masseter",
    "Sternocleidomastoid",
];
  
export const HEAD_AND_NECK_DEEP_MUSCLES = [
    "Scalenes"
];
  
export const HEAD_AND_NECK_WARM_UP = [
    "Scapula Position"
];
  
export const HEAD_AND_NECK_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, Located lower part of the neck on either side of the spine, rake with the pads the fingers up the headline. Apply gentle pressure with the pads of the fingers to the muscles of the upper jaw, gently stroke downwards and instruct the client to slowly open mouth.",
    "Friction application, Located the base of the skull and reinforce with the middle finger to apply pressure. Perform circular frictions. Located upper shoulder area and apply circular frictions. Apply circular frictions over the area of the temporalis muscle on both side of the head.",
    "Static compression, Place the pad on the index and middle finger over area lateral to the clavicular attachment of sternocleidomastoid. Apply pressure for 20 seconds in an inferior direction toward the 1st rib.",
    "Longitudinal Stripping (SCM), massage both side of neck and one side at a time. Turn the client head opposite to the side, locate the area behind the ear stroke down to the top of the collar bone. Apply gentle pressure toward the end of the movment.",
    "Longitudinal Stripping(Upper Trapeziuz), Bend and slightly turn the client head to the opposite side to where been working on. Gently stroke down from the hairline to the upper shoulder. Finish the movement just before the point of the shoulder.",
    "Longitudinal Stripping(Scalenes), With the clients head in the same position below the hairline, strip downward toward the base of the neck.",
];
  
export const HEAD_AND_NECK_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Shoulders (肩膀)
export const SHOULDERS_SUPERFICIAL_MUSCLES = [
    "Upper Trapezius",
    "Middle Trapezius",
    "Lower Trapezius",
    "Pectoralis Major",
    "Deltoids",
];
  
  
export const SHOULDERS_DEEP_MUSCLES = [
    "Rhomboids",
    "Rotator Cuff Muscles",
    "Levator Scapulae",
    "Splenius Capitis and Cervics",
    "Erector Spinae",
    "Pectoralis Major",
    "Subclavirs",
];
  
export const SHOULDERS_WARM_UP = [
    "Use general effleurage and petrissage on the shoulder area. Slide out toward the tips of the shoulder.",
    "Knead the trapezius muscle.",
    "Scapula Position",
    "With a light twisting movement stroke out the shoulder area",
];
  
export const SHOULDERS_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, Apply pressure gently between the spine and shoulder. Moves in a curve laterally along the shoulder in the supraspinous fossa. Massage on the outside borderof scapula. Strip in a downwards direction toward the ribs. Locate on posterior neck of the neck, strip outwards toward the points of the shoulders stopping short of the bone. Vary the stripping movement by gliding more posteriorly on the shoulder and the upper back.",
    "Friction , Apply circular frictions to the attachment points of the shoulder muscles on the side of the sternum and just inferior to the clavicle.",
    "Static compression, Using the thumb apply fentle static pressure to the insertion points of the shoulder muscles. Hold for 10-15 seconds.",
    "Transverse Stripping, Locate soft first just inferior to the clavicle. Apply a short stroke firmly in an inferior direction. With reinforced onto the shoulder just next to the neck, gently pull the muscle toward inner area, release pressure and repeat.",
];
  
export const SHOULDERS_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Back/Thoracic Region (后背)
export const BACK_THORACIC_SUPERFICIAL_MUSCLES = [
    "Middle Trapezius",
    "Latissimus Dorsi",
    "Lower Trapezius",
];
  
export const BACK_THORACIC_DEEP_MUSCLES = [
    "Posterior External Intercostals",
    "Deep Spinal Muscles (Multifidus and Rotatores)",
    "Erector Spinae",
    "Rhomboids",
];
  
export const BACK_THORACIC_WARM_UP = [
    "Stroke inferiorly from upper back toward the lower back.",
];
  
export const BACK_THORACIC_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, Locate the outside of the lower point of the scapula and apply preesure. Strip in a diagonal fashion inferiorly toward to the lumbar area. Apply pressure adjacent to the spine and the upper boarder of the shoulder blade. Strip the erector spinae toward the scapula.Strip down from the upper back area to the hip area.",
    "Transverse Stripping Deep Spinal Muscles, Reinforce in the paravertebral gutter in the middle back area. Twist slightly to strip transversely across multifidi.Strip upward toward the shoulder. Lean and Twist.",
    "Stripping to Intercostal Muscles, apply pressure adjacent to the spine and the upper boarder of the shoulder blade. Strip the erector spine toward the scapula. ",
    // add from LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES
    "Longitudinal Stripping, Using one hand as a guide place the soft fist in-between the fingers and abducted thumb. This will allow you to control the movement and avoid the bones of the spine. Strip down with the fist to the hip area.",
    "Transverse Stripping Deep Spinal Muscles, Place the thumb of one hand in the paravertebral gutter adjacent to the spine. Reinforce the thumb with the heel of the upper hand to add pressure. Strip with the thumb up the paravertebral gutter. Hook in with reinforced fingers at the side of the waist. Pull fingers toward the spine, slowly lowering pressure as you go. Form a bridge across the spine with the fingers other side and thumbs the side clos to you and thumbs with both hands. The thumbs should be located in the paravertebral gutter. Strip across the erector spine with the thumbs. ",
    "Static Compression, Pronate the hand and locate the upper forearm in the side of the lumbar area. Apply pressure slowly to client’s comfort level. Hold this pressure statically for a count 15-20 seconds. Ensuring the client is breathing deeply.",
    "Friction Applications, Place fingers on the spinal muscles of the paravertebral gutter. Reinforce these fingers with the fingers of your other hand. Apply both transverse and circular fictions. Be sure not to slide over the skin, you are trying to move the upper tissue over the deeper body tissue. Move along the back.",
];
  
export const BACK_THORACIC_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Lumbar Region (LOWER BACK) (腰)
export const LUMBAR_REGION_SUPERFICIAL_MUSCLES = [
    "Lateral parts of internal ",
    "Latissimus Dorsi",
    "Lower Trapezius  ",
    "External Oblique Abdominals",
];
  
export const LUMBAR_REGION_DEEP_MUSCLES = [
    "Quadratus Lumborum",
    "Deep Spinal Muscles(Multifidus and Rotatores)",
    "Erector Spinae",
];
  
export const LUMBAR_REGION_WARM_UP = [
    "Use general effleurage and petrissage on the lumbar area",
];
  
export const LUMBAR_REGION_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, Using one hand as a guide place the soft fist in-between the fingers and abducted thumb. This will allow you to control the movement and avoid the bones of the spine. Strip down with the fist to the hip area.",
    "Transverse Stripping Deep Spinal Muscles, Place the thumb of one hand in the paravertebral gutter adjacent to the spine. Reinforce the thumb with the heel of the upper hand to add pressure. Strip with the thumb up the paravertebral gutter. Hook in with reinforced fingers at the side of the waist. Pull fingers toward the spine, slowly lowering pressure as you go. Form a bridge across the spine with the fingers other side and thumbs the side clos to you and thumbs with both hands. The thumbs should be located in the paravertebral gutter. Strip across the erector spine with the thumbs. ",
    "Static Compression, Pronate the hand and locate the upper forearm in the side of the lumbar area. Apply pressure slowly to client’s comfort level. Hold this pressure statically for a count 15-20 seconds. Ensuring the client is breathing deeply.",
    "Friction Applications, Place fingers on the spinal muscles of the paravertebral gutter. Reinforce these fingers with the fingers of your other hand. Apply both transverse and circular fictions. Be sure not to slide over the skin, you are trying to move the upper tissue over the deeper body tissue. Move along the back.",
];
  
export const LUMBAR_REGION_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Hips 胯
export const HIPS_SUPERFICIAL_MUSCLES = [
    "Gluteus Maximus",
    "Lateral fibres of Gluteus Medius",
];
  
export const HIPS_DEEP_MUSCLES = [
    "Medial fibres of Gluteus Medius",
    "Gluteus Medius",
    "Piriformis",
];
  
export const HIPS_WARM_UP = [
    "Use general effleurage and petrissage on the Pelvic area",
];
  
export const HIPS_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping,under the crest of the hip, strip inferiorly toward the greater trochanter.",
    "Transverse Stripping Deep Spinal Muscles, to the side of the upper hip area, rake back along the upper buttock muscles. Apply pressure to the thickest part of buttock muscles just to the side of the sacrum. Slowly strip out toward the side of the upper hip area.  ",
    "Compression, apply pressure to the thickest part of buttock muscles just to the side of the sacrum. Slowly strip out toward the side of the upper hip area.",
    "Friction Applications, apply circular frictions with reinforced to the muscle attachment points to the side of the sacrum and below the crest of the hip. Apply frictions to the side of the hip above the hip bone.",
];
  
export const HIPS_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Arm Region (胳膊)
export const ARM_REGION_SUPERFICIAL_MUSCLES = [
    "Deltoids",
    "Biceps Brachii",
    "Triceps Brachii",
    "Brachioradialis",
    "Flexor group of wrist and hand",
    "Extensor group of wrist and hand",
];
  
export const ARM_REGION_DEEP_MUSCLES = [
    "Brachialis",
    "Coracobrachialis",
];
  
export const ARM_REGION_WARM_UP = [
    "Use general effleurage and petrissage on arm area",
    "Locate the thumb above the wrist toward the middle of the forearm. Place arm flat on the massage",
];
  
export const ARM_REGION_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, Turn the client’s hand palms up and hold client’s hand and wrist. Bend the Client’s elbow with reinforced thumb strip upward.Apply excessive pressure when working over area with little muscle bulk. Stop before reaching the front of the elbow. Hold arm across the chest and support. Locate the soft fist above the pint of the elbow . Strip towards the upper part of the arm stopping at the shoulder.Rest the client elbow, stabilize the client’s arm and locate the thumb on the biceps. Strip with the thumb upwrd over the anterior arm toward the shoulder.",
    "Transverse Stripping Deep Spinal Muscles, Turn the client’s hand palms up and hold their hand and wrist. Bend the client’s elbow with the thenar sides of palms and thumbs move hands apart across the forearm. Apply excessive pressure when working  over areas with little muscle bulk. Progressively move up the arm and repeat.",
];
  
export const ARM_REGION_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Thigh (legs) Region (腿)
export const THIGH_REGION = [
    "Gracilis, Inferior portion of Sartorius Adductor Longus",
    "Deep to these are adductor brevis and adductor magnus",
];
  
export const THIGH_REGION_DEEP_MUSCLES = [
    "Quadratus Lumborum",
    "Deep Spinal Muscles (Multifidus and Rotatores)",
    "Erector Spinae  ",
];
  
export const THIGH_REGION_WARM_UP = [
    "Use general effleurage and petrissage on the thigh area, Apply pressure on the lliotibial band",
];
  
export const THIGH_REGION_DEEP_TISSUES_TECHNIQUES = [
    "Longitudinal Stripping, strip up from th eside of the thigh to the hip.",
    "Transverse Stripping , graps the side of the thigh and separate in increments p the legs.",
    "Static Compression, reinforce and apply pressure over the area. Apply pressure over the area and reinforce on another side of thigh muscle.",
    "Friction Applications, apply frictions to the lower attachment points at the side of the knee cap.",
];
  
export const THIGH_REGION_APPLICATION = [
    "Essential oil",
    "Tiger Balm",
    "Hot pack",
    "Hot stone",
];
  
  // Treatment Plan    (治疗意见,任选1到3个即可)
export const TREATMENT_PLAN = [
    "Basically returned to normal, follow up visit when feel uncomfortable.",
    "Getting better, already schedule for next visit.",
    "Getting better, not schedule for next visit yet.",
    "In recovery progress, already schedule for next visit.",
    "Symptoms are evident and show no sign of decreasing, suggest to seek medical advice.",
];
  
export const DRAPING_TECHNIQUE = [
    "Draping technique been use to protect client's privacy. Client feels covered, warm and comfortable."
];
  
  // Repeat   治疗期限 (一边选一个月一次或者两次 或者2个月一次)
export const TREATMENT_REPEAT_TIMES = [
    "1 times",
    "2 times",
    "3 times",
    "4 times",
    "5 times",
    "6 times",
    "7 times",
];
  
export const TREATMENT_REPEAT_WEEK_OR_MONTH = [
    "Per week",
    "Per two week",
    "Per month",
    "Per two month",
];
  
  // Advice to Client
export const ADVICE_TO_CLIENT = [
    "Well balance diet",
    "Avoid wearing tight clothes",
    "Teach progressive relaxation technique",
    "Educate client on good posture",
    "Educated client on breathing exercise",
    "Avoid bending the low back",
    "Avoid prolonged time on the computer",
    "Avoid overloading muscles",
    "Do functional exercise every day",
    "Do home stretches",
    "Take more rest",
    "Have the treatment regularly",
    "Do not stand for too long",
    "Don not bend the neck for too long",
    "Do not lift heavy things",
    "Do not walk too long",
];
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  