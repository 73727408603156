export const CHECK_POINTS_API = {
  url: `/self_register/check_points`,
  method: 'post'
}

export const CREATE_PATIENT_API = {
  url: `/self_register/create_by_patient_self`,
  method: 'post'
};

export const AUTHENTICATE_PATIENT_API = {
  url: `/self_register/check_patient_exist/:id`,
  method: 'post'
};

export const GENERATE_PATIENT_AND_CONSULTATION_API = {
  url: `/self_register/generate_patient_and_consultation/:id`,
  method: 'post'
}

export const VIP_REGISTER_API = {
  url: `/self_register/vip_register`,
  method: 'post'
}

export const SEND_VERIFICATION_SMS_API = {
  url: `/self_register/send_verification_sms`,
  method: 'post'
}

export const VERIFY_MOBILE_CODE_API = {
  url: `/self_register/verify_mobile_code`,
  method: 'post'
}

