import {
  CREATE_PATIENT_API,
  AUTHENTICATE_PATIENT_API,
  GENERATE_PATIENT_AND_CONSULTATION_API,
  VIP_REGISTER_API,
  SEND_VERIFICATION_SMS_API,
  VERIFY_MOBILE_CODE_API,
  CHECK_POINTS_API,

} from "api/patient/constants";
import api from "api/api";
import { generateRoute } from "helpers/routesUtils";

export const apiCheckPoints = ({ mobile }) => {
  const { url, method } = CHECK_POINTS_API;
  return api({
    method,
    url,
    data: { mobile },
  });
  
};


// export const CHECK_POINTS_API = {
//   URL: `/self_register/check_points`,
//   method: 'post'
// }
export const apiCreatePatient = ({ patient }) => {
    const { url, method } = CREATE_PATIENT_API;
    return api({
        method,
        url,
        data: patientParams({ patient }),
    });
};

export const apiAuthenticatePatientAPI = ({ patient, clinicId }) => {
  const { url, method } = AUTHENTICATE_PATIENT_API;
  return api({
    method,
    url: generateRoute(url, clinicId),
    data: patientParams({ patient }),
  });
};

export const apiGeneratePatientAndConsultation = ({ patient, consultation, clinicId }) => {
  const { url, method } = GENERATE_PATIENT_AND_CONSULTATION_API;
  const data = {  patient: simplePatientParams({patient}), consultation: simpleConsultationParams({consultation})}
  return api({
    method,
    url: generateRoute(url, clinicId),
    data: data
  })
};

export const apiSendVerificationSMS = ({ mobile }) => {
  const { url, method } = SEND_VERIFICATION_SMS_API;
  return api({
    method,
    url,
    data: { mobile },
  });
};

export const apiVerifyMobileCode = ({ mobile, code }) => {
  const { url, method } = VERIFY_MOBILE_CODE_API;
  return api({
    method,
    url,
    data: { mobile, code },
  });
};

export const apiVIPRegister = ({ patient }) => {
  const { url, method } = VIP_REGISTER_API;
  return api({
    method,
    url,
    data: { patient },
  });
};

const patientParams = ({ patient }) => {
  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    mobile,
    homePhone,
    gender,
    address,
    occupation,
    preferredLanguage,
    note,
    signature,
    privateHealthFundName,
    privateHealthFundMembershipNumber,
    privateHealthFundMembershipCode,
    emergencyContact,
    emergencyPhone,
  } = patient;

  return {
    patient: {
      email,
      firstName,
      lastName,
      dateOfBirth,
      mobile,
      homePhone,
      gender,
      address,
      occupation,
      preferredLanguage,
      note,
      signature,
      privateHealthFundName,
      privateHealthFundMembershipNumber,
      privateHealthFundMembershipCode,
      emergencyContact,
      emergencyPhone,
    }
  }
};


const simpleConsultationParams = ({ consultation }) => {
  const {
    musculoskeletal,
    nervous,
    cardiovascular,
    respiratory,
    reactions,
    reactionsNote,
    otherConditions,
    otherConditionsNote,
    medication,
    painOrTenderness,
    stiffness,
    numbnessOrTingling,
    swelling,
    signature,
    parentPrintName,
    relationshipWithPatient,
    guardianSignature,
    bodyFocusImage,
  } = consultation;

  return {
    musculoskeletal,
    nervous,
    cardiovascular,
    respiratory,
    reactions,
    reactionsNote,
    otherConditions,
    otherConditionsNote,
    medication,
    painOrTenderness,
    stiffness,
    numbnessOrTingling,
    swelling,
    signature,
    parentPrintName,
    relationshipWithPatient,
    guardianSignature,
    bodyFocusImage,
  }
};

const simplePatientParams = ({ patient }) => {
  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    mobile,
    homePhone,
    gender,
    address,
    occupation,
    preferredLanguage,
    note,
    signature,
    privateHealthFundName,
    privateHealthFundMembershipNumber,
    privateHealthFundMembershipCode,
    emergencyContact,
    emergencyPhone,
  } = patient;

  // 生成一个随机的4字符字符串
  const randomString = Math.random().toString(36).substring(2, 6);

  // 将随机字符串附加到mobile后面
  const modifiedMobile = `${mobile}_${randomString}`;

  return {
    email: email || `__noemail.${modifiedMobile}@allaroundmassage.com.au`,
    firstName,
    lastName,
    dateOfBirth,
    mobile,
    homePhone,
    gender,
    address,
    occupation,
    preferredLanguage,
    note,
    signature,
    privateHealthFundName,
    privateHealthFundMembershipNumber,
    privateHealthFundMembershipCode,
    emergencyContact,
    emergencyPhone,
  }
};
