import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  LOG_PATH,
  REFRESH_USER,
  CHECK_USER,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CLEAR_USER,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SETUP_ACCOUNT,
  SETUP_ACCOUNT_SUCCESS,
  SETUP_ACCOUNT_FAILED,
  FETCH_CURRENT_STAFF,
  FETCH_CURRENT_STAFF_FAILED, FETCH_CURRENT_STAFF_SUCCESS, REGISTER_SETUP_STEP, REGISTER_SET_USER_DATA,
  REGISTER_SETUP_CONSULTATION,
  REGISTER_SUBMIT_FORM,
  REGISTER_FORM_UNSUBMITED,
  REGISTER_FORM_SUBMITED,
  REGISTER_SETUP_ALLOW_TO_NEXT,
  VIP_REGISTER_SUBMIT_FORM_SUCCESS,
  VIP_REGISTER_SUBMIT_FORM_FAILED,
  SEND_VERIFICATION_SMS,
  SEND_VERIFICATION_SMS_SUCCESS,
  SEND_VERIFICATION_SMS_FAILED,
  VERIFY_MOBILE_CODE,
  VERIFY_MOBILE_CODE_SUCCESS,
  VERIFY_MOBILE_CODE_FAILED,
  CHECK_POINTS_REQUEST,
  CHECK_POINTS_SUCCESS,
  CHECK_POINTS_FAILED,
} from 'state/auth/authConstants';

import { initJwtToken, initIsLoggedIn } from "helpers/authUtils";

const INIT_STATE = {
  isLoggedIn: initIsLoggedIn(),
  currentUser: {},
  token: initJwtToken(),
  loading: false,
  logPath: '/',
  error: null,
  allowToNext: true,
  isVerified: false,
  // patient: null,
  patient: null,
  points: null,
  userData: {
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    mobile: '',
    homePhone: '',
    gender: '',
    address: '',
    occupation: '',
    preferredLanguage: '',
    note: '',
    signature: '',
    privateHealthFundName: '',
    privateHealthFundMembershipNumber: '',
    privateHealthFundMembershipCode: '',
    emergencyContact: '',
    emergencyPhone: '',
  },
  consultation: {
    musculoskeletal: [],
    nervous: [],
    cardiovascular: [],
    respiratory: [],
    reactions: [],
    reactionsNote: '',
    otherConditions: [],
    otherConditionsNote: '',
    medication: '',
    painOrTenderness: '',
    stiffness: '',
    numbnessOrTingling: '',
    swelling: '',
    signature: '',
    parentPrintName: '',
    relationshipWithPatient: '',
    guardianSignature: '',
    bodyFocusImage: '',
  },
  step: 1,
  formSubmitted: false,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS: {

      const { token: loginToken, user: loginUser } = action.payload;
      return { ...state, token: loginToken, currentUser: loginUser, loading: false, error: null, isLoggedIn: true };
    }

    case LOGIN_USER_FAILED:
      return {
        ...state,
        token: null,
        currentUser: null,
        error: action.payload.error,
        loading: false,
        isLoggedIn: false
      };

    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        token: null,
        isLoggedIn: false,
        error: null,
        loading: false
      };

    case CLEAR_USER:
      return {
        ...state,
        currentUser: null,
        token: null,
        isLoggedIn: false,
        error: null,
        loading: false
      };

    case LOG_PATH: {

      const { path: logPath } = action.payload;
      return { ...state, logPath: logPath };
    }

    case CHECK_USER:
      return { ...state };

    case REFRESH_USER:
      return {
        ...state,
        currentUser: action.payload.current.currentAuth,
        isLoggedIn: true
      };

    case FORGET_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload.passwordResetStatus,
        loading: false,
        error: null
      };

    case FORGET_PASSWORD_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SETUP_ACCOUNT:
      return {
        ...state,
        loading: true,
      };

    case SETUP_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SETUP_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_CURRENT_STAFF:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CURRENT_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case FETCH_CURRENT_STAFF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case REGISTER_SETUP_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    case REGISTER_SETUP_ALLOW_TO_NEXT:
      return {
        ...state,
        allowToNext: action.payload.allowToNext,
      };
    
    case REGISTER_SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.key]: action.payload.value,
        },
      };
    
    case REGISTER_SETUP_CONSULTATION:
      return {
        ...state,
        consultation: {
          ...state.consultation,
          [action.payload.key]: action.payload.value,
        }
      };
    
    case REGISTER_SUBMIT_FORM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    
    case REGISTER_FORM_SUBMITED:
      return {
        ...state,
        loading: false,
        step: 15,
        patient: action.payload.patient,
      };
    
    case REGISTER_FORM_UNSUBMITED:
      return {
        ...state,
        loading: false,
        patient: null,
        error: action.payload.error,
        step: 16,
      };
    
    case VIP_REGISTER_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: action.payload.patient,
        isVerified: false, // 重置验证状态
        error: null,
      };
    
    case VIP_REGISTER_SUBMIT_FORM_FAILED:
      return {
        ...state,
        loading: false,
        patient: null,
        error: action.payload.error,
      };

    case SEND_VERIFICATION_SMS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SEND_VERIFICATION_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SEND_VERIFICATION_SMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case VERIFY_MOBILE_CODE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case VERIFY_MOBILE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
        error: null,
      };

    case VERIFY_MOBILE_CODE_FAILED:
      return {
        ...state,
        loading: false,
        isVerified: false,
        error: action.payload.error,
      };

    case CHECK_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        points: null,
      };

    case CHECK_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        points: action.payload.points,
        error: null,
      };

    case CHECK_POINTS_FAILED:
      return {
        ...state,
        loading: false,
        points: null,
        error: action.payload.error,
      };
  

    default:
      return { ...state };
  }
};

export default authReducer;
